body {
  position: relative;
  box-sizing: border-box;
  line-height: 1.5;
  font-family: Muli, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Muli", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
*,
*:before,
*::after {
  margin: 0;
  padding: 0;
}
main {
  width: 100%;
  height: 100%;
  background: #fdfbfb;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
button {
  cursor: pointer;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.w-10p {
  width: 10% !important;
}
.w-90p {
  width: 90% !important;
}
.w-20p {
  width: 30% !important;
}
.w-80p {
  width: 70% !important;
}
.w-30p {
  width: 30% !important;
}
.w-70p {
  width: 70% !important;
}
.w-auto {
  width: auto !important;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
input:disabled {
  background: transparent;
}
.p-0 {
  padding: 0 !important;
}
.plr10 {
  padding: 0 10px !important;
}
.plr47 {
  padding: 0 47px !important;
}
.m-0 {
  margin: 0 !important;
}
.h-inherit {
  height: inherit !important;
}
.formFull.justify-between {
  justify-content: space-between;
}
.formFull.justify-between p {
  width: 50%;
}
p.success-text {
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: rgba(0, 128, 0, 0.8);
}
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  background: #f3f7ff;
}
.nav.testers-nav {
  background: #FCF9F9;
}
.nav-profile-container {
  position: relative;
}
.nav-profile-container .profile-menu {
  position: absolute;
  top: 44px;
  width: 153px;
  height: auto;
  background: linear-gradient(180deg, #FFFFFF 0.86%, #FEFEFE 100%);
  border: 1px solid rgba(130, 127, 152, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(130, 127, 152, 0.05);
  border-radius: 5px;
}
.nav-profile-container .profile-menu.show {
  display: block;
}
.nav-profile-container .profile-menu.hide {
  display: none;
}
.nav-profile-container .profile-menu .menu-wrapper {
  padding: 20px;
}
.nav-profile-container .profile-menu .menu-wrapper .menu-list p {
  margin-left: 10px;
}
.nav-profile-container .profile-menu .menu-wrapper .menu-list .list-item {
  margin-bottom: 19.17px;
  cursor: pointer;
}
header {
  background: rgba(251, 246, 246, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.align-center {
  align-items: center !important;
}
.hr-down {
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
}
.formStepsSection {
  border-bottom: 1px solid rgba(130, 127, 152, 0.1);
  box-shadow: 0px 10px 10px #FDFBFB;
  background: #FCF9F9;
}
.formStepsSection .step-container {
  display: none;
}
@media only screen and (max-width: 529px) {
  .formStepsSection .step-container {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background: #fcf9f9;
    border: 1px solid rgba(130, 127, 152, 0.2);
    box-sizing: border-box;
    box-shadow: 0px -10px 10px rgba(130, 127, 152, 0.05);
  }
}
.formStepsSection .step-container .step {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(39, 109, 247, 0.8);
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 225px;
  height: 43px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.formStepsSection .step-container .step span {
  margin-left: 10px;
}
.formStepsSection .step-container .step img {
  margin-left: 20px;
}
@media only screen and (max-width: 529px) {
  .formStepsSection {
    width: 100vw;
  }
}
.formStepsSection .container {
  width: 60%;
  margin: 0 auto;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .formStepsSection .container {
    width: 90%;
  }
}
@media only screen and (max-width: 529px) {
  .formStepsSection .container {
    display: none;
  }
}
.formStepsSection .container .steps {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  font-family: Muli;
}
.formStepsSection .container .steps .step {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 20px;
}
.formStepsSection .container .steps .step img {
  margin-right: 20px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .formStepsSection .container .steps .step {
    padding: 10px;
  }
  .formStepsSection .container .steps .step img {
    margin-right: 6px;
  }
}
.formStepsSection .container .step.selected {
  border: 1px solid rgba(39, 109, 247, 0.8);
}
.formStepsSection .container .step.selected > span {
  color: #276df7;
}
.steps.mobile {
  display: none;
}
@media only screen and (max-width: 529px) {
  .steps.mobile {
    display: flex;
    align-items: center;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.12);
    height: 100px;
    padding: 0 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}
.formSection {
  padding-top: 303px;
}
.formSection .container {
  width: 60%;
  margin: 0 auto;
}
@media only screen and (max-width: 529px) {
  .formSection .container {
    width: 90%;
  }
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .formSection .container {
    width: 90%;
  }
}
.formSection .container .step-error {
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  font-size: 14px;
  padding-right: 18px;
  color: #bc3e06;
}
@media only screen and (max-width: 529px) {
  .formSection .container .step-error {
    justify-content: center;
    padding-right: 0;
  }
}
.formSection .container .panelControls {
  margin-top: 15px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
}
.formSection .container .panelControls .cancel {
  color: #276df7;
  cursor: pointer;
}
.formSection .container .panelControls .cancel .cancel-x {
  margin-right: 5px;
}
.formSection .container .panelControls .cancel .back {
  font-weight: bold;
}
.formSection .container .panelControls .saveContinue {
  width: 248px;
  height: 60px;
  background: #276df7;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  transition: 0.15s ease;
  transition-property: background-color, border-color, box-shadow, color;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  margin-left: auto;
}
.formSection .container .panelControls .saveContinue svg {
  margin-left: 10px;
}
.formSection .container .form {
  display: flex;
  flex-direction: column;
}
.formSection .container .form .formGrid {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 100px;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid {
    flex-direction: column;
  }
}
.formSection .container .form .formGrid .gridItem {
  width: 50%;
  display: flex;
  align-items: baseline;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid .gridItem {
    width: 100%;
  }
}
.formSection .container .form .formGrid .gridItem .itemTitle {
  font-family: Muli;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #3b3b3b;
  margin-bottom: 7px;
}
.formSection .container .form .formGrid .gridItem .itemSubTitle {
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid .gridItem .itemSubTitle {
    margin-bottom: 30px;
  }
}
.formSection .container .form .formGrid .gridItem .formControl {
  width: 100%;
}
.formSection .container .form .formGrid .gridItem .formControl .itemSelected {
  color: #276df7;
  background: rgba(39, 109, 247, 0.05) !important;
  border: 1.5px solid rgba(39, 109, 247, 0.8) !important;
}
.formSection .container .form .formGrid .gridItem .formControl .itemDisabled {
  opacity: 0.5;
}
.formSection .container .form .formGrid .gridItem .formControl .formFull {
  width: 100%;
  display: flex;
  position: relative;
}
.formSection .container .form .formGrid .gridItem .formControl .formFull .mr-10 {
  margin-right: 10% !important;
}
.formSection .container .form .formGrid .gridItem .formControl .formFull .mr-20 {
  margin-right: 20px;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid .gridItem .formControl .formFull .mr-20 {
    margin: 0;
  }
}
.formSection .container .form .formGrid .gridItem .formControl .formFull .select {
  margin-bottom: 25px !important;
}
.formSection .container .form .formGrid .gridItem .formControl .formFull .radio-button-error {
  position: absolute;
  bottom: -25px;
  font-size: 14px;
  color: #bc3e06;
  width: 100%;
}
.formSection .container .form .formGrid .gridItem .formControl .formInputWrapper {
  height: 65px;
  background: #ffffff;
  border: 1px solid rgba(130, 127, 152, 0.4);
  border-radius: 8px;
  margin-bottom: 25px;
}
.formSection .container .form .formGrid .gridItem .formControl .formInputWrapper input {
  width: 95%;
  margin: 0;
  padding: 0;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid .gridItem .formControl .formInputWrapper input {
    width: 85%;
  }
}
.formSection .container .form .formGrid .gridItem .formControl .formInputWrapper input:focus,
.formSection .container .form .formGrid .gridItem .formControl .formInputWrapper input:active {
  border: none;
  outline: 0;
}
.formSection .container .form .formGrid .gridItem .formControl .formWrapper {
  height: 65px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 20px;
  border: 1px solid rgba(130, 127, 152, 0.4);
  margin-bottom: 25px;
}
.formSection .container .form .formGrid .gridItem .formControl .formWrapper label {
  color: #827f98;
  font-weight: 500;
  font-size: 13px;
}
.formSection .container .form .formGrid .gridItem .formControl .formWrapper input[type="text"],
.formSection .container .form .formGrid .gridItem .formControl .formWrapper input[type="email"],
.formSection .container .form .formGrid .gridItem .formControl .formWrapper input[type="date"] {
  width: 100%;
  height: inherit;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 0px;
  color: #3b3b3b;
  font-weight: 600;
  font-size: 16px;
}
.formSection .container .form .formGrid .gridItem .formControl .formWrapper.flexColumn {
  display: flex;
  flex-direction: column;
}
.formSection .container .form .formGrid .gridItem .formControl .formWrapper.formHalf {
  width: 50%;
}
@media only screen and (max-width: 529px) {
  .formSection .container .form .formGrid .gridItem .formControl .formWrapper.formHalf {
    width: 45%;
  }
}
footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 131px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
@media only screen and (max-width: 529px) {
  footer {
    margin-bottom: 120px;
  }
}
footer .footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .footerContainer p {
  margin-right: 6px;
}
.flex {
  display: flex;
}
.flexRow {
  display: flex;
  flex-direction: column;
}
.flexColumn {
  flex-direction: column;
}
.flexColumn.name-email div {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: rgba(38, 38, 38, 0.8);
  margin-bottom: 8px;
}
.flexColumn.name-email div:last-child {
  font-weight: 500;
  color: rgba(38, 38, 38, 0.6);
  margin-bottom: 0;
}
.justifyBetween {
  justify-content: space-between;
}
.justifyCenter {
  justify-content: center;
}
.justifyAround {
  justify-content: space-around !important;
}
.alignCenter {
  align-items: center;
}
@media only screen and (max-width: 529px) {
  .saveContinue {
    width: 175px !important;
  }
}
.formDate {
  background: #ffffff !important;
  border: 1px solid rgba(130, 127, 152, 0.4) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
}
@media only screen and (max-width: 529px) {
  .formDate {
    -webkit-min-logical-width: calc(100% - 16px);
  }
}
.formDate.error,
.formInputWrapper.error,
.formWrapper.error {
  border: 1px solid #bc3e06 !important;
}
input.borderless {
  border: none;
  outline: none;
  width: 100%;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #276df7;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/** DASHBOARD SECTION **/
.lbAdminFormWrapper {
  width: 100%;
}
.lbAdminFormWrapper .lbAdminForm {
  margin: 106px auto;
  width: 491px;
  min-height: 506px;
  background: #ffffff;
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
}
.lbAdminFormWrapper .lbAdminForm.reset-form {
  min-height: auto;
  height: auto;
}
@media only screen and (max-width: 529px) {
  .lbAdminFormWrapper .lbAdminForm {
    width: 90%;
    height: auto;
  }
}
.lbAdminFormWrapper .lbAdminForm h2 {
  margin-top: 50px;
  margin-bottom: 15px;
}
.lbAdminFormWrapper .lbAdminForm p.welcome {
  margin-bottom: 50px;
}
.lbAdminFormWrapper .lbAdminForm p.forgot-password {
  margin-right: 50px;
  text-align: right;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #276df7;
  cursor: pointer;
}
.lbAdminFormWrapper .lbAdminForm .input-row {
  background: #ffffff;
  height: 65px;
  border: 1px solid rgba(130, 127, 152, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  width: 70%;
  min-width: 391px;
  margin: 0 auto 25px auto;
  text-align: start;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 529px) {
  .lbAdminFormWrapper .lbAdminForm .input-row {
    width: 90%;
    min-width: unset;
  }
}
.lbAdminFormWrapper .lbAdminForm .input-row label {
  margin-top: 5px;
}
.lbAdminFormWrapper .lbAdminForm .input-row input {
  outline: none;
  border: none;
  width: 100%;
  height: max-content;
}
@media only screen and (max-width: 529px) {
  .lbAdminFormWrapper .lbAdminForm .input-row input {
    width: 90%;
  }
}
.lbAdminFormWrapper .lbAdminForm .input-row .h-30 {
  height: 30px;
}
.lbAdminFormWrapper .lbAdminForm .input-row .showPassword {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
}
.btn {
  width: 248px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  transition: 0.15s ease;
  transition-property: background-color, border-color, box-shadow, color;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}
.primary-btn {
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 48px auto 0 auto;
}
.primary-bg {
  background: #fef9f9;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.main {
  width: 100vw;
  height: 100vh;
}
.main-bg {
  width: 100vw;
  min-height: 100vh;
  background: #fdfeff;
  padding-top: 75px;
}
/** Auth Nav **/
.auth-nav {
  width: 100vw;
  height: 75px;
  background: #F9FBFF !important;
  border: 1px solid rgba(130, 127, 152, 0.2);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
.auth-nav .nav-container {
  width: 90%;
  margin: 0 auto;
  height: 57px;
}
.auth-nav .nav-container .mt-18 {
  margin-top: 18px !important;
}
.auth-nav .nav-container .nav-list {
  list-style-type: none;
  height: 56px;
}
.auth-nav .nav-container .nav-list li {
  box-sizing: border-box;
}
.auth-nav .nav-container .nav-list li:not(:last-child) {
  margin-right: 50px;
  height: 100%;
}
.auth-nav .nav-container .nav-list li.active {
  border-bottom: 3px solid #276df7;
}
.auth-nav .nav-container .nav-list li.active a p {
  color: #276df7;
}
.auth-nav .nav-container .nav-list .nav-icon,
.auth-nav .nav-container .nav-list svg {
  margin-right: 7px;
}
.auth-nav .nav-container .profile-avatar {
  width: 30px;
  height: 30px;
  background: green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 7px;
}
.auth-nav .nav-container .profile-name {
  margin-right: 9px;
}
.lbDispatchSection .container,
.app-section .container {
  width: 90%;
  margin: 65px auto;
}
.lbDispatchSection .container .sideBarContainer,
.app-section .container .sideBarContainer {
  width: 80%;
}
.lbDispatchSection .container .sideBarContainer .sideBarNav,
.app-section .container .sideBarContainer .sideBarNav {
  width: 70%;
  height: auto;
  background: rgba(39, 109, 247, 0.03);
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px #fdfbfb;
  border-radius: 5px;
}
.lbDispatchSection .container .sideBarContainer .sideBarNav ul,
.app-section .container .sideBarContainer .sideBarNav ul {
  padding-top: 20px;
  list-style: none;
}
.lbDispatchSection .container .sideBarContainer .sideBarNav ul li,
.app-section .container .sideBarContainer .sideBarNav ul li {
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  padding-left: 46px;
  cursor: pointer;
}
.lbDispatchSection .container .sideBarContainer .sideBarNav ul li.focus,
.app-section .container .sideBarContainer .sideBarNav ul li.focus {
  border-right: 3px solid #276df7;
  background: rgba(130, 127, 152, 0.1);
}
.lbDispatchSection .container .listingSection .filter .searchInputWrapper,
.app-section .container .listingSection .filter .searchInputWrapper {
  background: linear-gradient(180deg, #ffffff 0.86%, #fefefe 100%);
  border: 1px solid rgba(38, 38, 38, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  width: 290px;
  padding: 0 16px;
  margin-right: 20px;
}
.lbDispatchSection .container .listingSection .filter .searchInputWrapper svg,
.app-section .container .listingSection .filter .searchInputWrapper svg {
  margin-right: 12px;
}
.lbDispatchSection .container .listingSection .filter .searchInputWrapper input,
.app-section .container .listingSection .filter .searchInputWrapper input {
  width: 80%;
  border: none;
  outline: none;
}
.lbDispatchSection .container .listingSection .table,
.app-section .container .listingSection .table {
  width: 100%;
  height: auto;
  margin-top: 35px;
}
.lbDispatchSection .container .listingSection .table table,
.app-section .container .listingSection .table table {
  width: 100%;
  border-collapse: collapse;
}
.lbDispatchSection .container .listingSection .table table .badges,
.app-section .container .listingSection .table table .badges {
  height: 27px;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 5px;
  white-space: nowrap;
  padding: 0 10px;
  border: none;
}
.lbDispatchSection .container .listingSection .table table .badges.default,
.app-section .container .listingSection .table table .badges.default {
  background: #827f98;
  color: #827f98;
}
.lbDispatchSection .container .listingSection .table table .badges.new,
.app-section .container .listingSection .table table .badges.new {
  background: red;
}
.lbDispatchSection .container .listingSection .table table .badges.result_updated,
.app-section .container .listingSection .table table .badges.result_updated {
  background: #e3fcef;
  color: #00875a;
}
.lbDispatchSection .container .listingSection .table table .badges.sample_collected,
.app-section .container .listingSection .table table .badges.sample_collected {
  color: #f1743a;
  background: #fdf1eb;
}
.lbDispatchSection .container .listingSection .table table .badges.sample_delivered,
.app-section .container .listingSection .table table .badges.sample_delivered {
  color: #ff991f;
  background: #fef5e9;
}
.lbDispatchSection .container .listingSection .table table .badges.kit_delivered,
.app-section .container .listingSection .table table .badges.kit_delivered {
  background: #e7e8f8;
  color: #0d1cb8;
}
.lbDispatchSection .container .listingSection .table table .badges.pending,
.app-section .container .listingSection .table table .badges.pending {
  background: #f3f2f5;
  color: #827f98;
}
.lbDispatchSection .container .listingSection .table table .badges.lab_received,
.app-section .container .listingSection .table table .badges.lab_received {
  color: #ff991f;
  background: #fef5e9;
}
.lbDispatchSection .container .listingSection .table table .badges.negative,
.app-section .container .listingSection .table table .badges.negative {
  background: #e3fcef;
  color: #00875a;
}
.lbDispatchSection .container .listingSection .table table .badges.positive,
.app-section .container .listingSection .table table .badges.positive {
  background: #fbe8e8;
  color: #de1e1e;
}
.lbDispatchSection .container .listingSection .table th,
.app-section .container .listingSection .table th {
  height: 60px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  text-align: start;
}
.lbDispatchSection .container .listingSection .table tr,
.app-section .container .listingSection .table tr {
  border-bottom: 1px solid green;
}
.lbDispatchSection .container .listingSection .table .tr,
.app-section .container .listingSection .table .tr {
  height: 98px !important;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1) !important;
}
.lbDispatchSection .container .listingSection .table table,
.app-section .container .listingSection .table table {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  border-radius: 5px;
}
.lbDispatchSection .container .listingSection .table table .th-center,
.app-section .container .listingSection .table table .th-center,
.lbDispatchSection .container .listingSection .table table .td-center,
.app-section .container .listingSection .table table .td-center {
  text-align: center;
}
.lbDispatchSection .container .listingSection .table table td.avatar_td,
.app-section .container .listingSection .table table td.avatar_td {
  width: 108px;
  box-sizing: border-box;
}
.lbDispatchSection .container .listingSection .table table td.avatar_td .avatar,
.app-section .container .listingSection .table table td.avatar_td .avatar {
  width: 48px;
  height: 48px;
  background: #f1743a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.lbDispatchSection .container .listingSection .table table button.view-btn,
.app-section .container .listingSection .table table button.view-btn,
.lbDispatchSection .container .listingSection .table table button.reschedule-btn,
.app-section .container .listingSection .table table button.reschedule-btn {
  height: 36px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  white-space: no-wrap;
}
.lbDispatchSection .container .listingSection .table table button.view-btn,
.app-section .container .listingSection .table table button.view-btn {
  border: 1px solid rgba(39, 109, 247, 0.6);
  color: #276df7;
  width: auto;
  white-space: nowrap;
}
.lbDispatchSection .container .listingSection .table table button.reschedule-btn,
.app-section .container .listingSection .table table button.reschedule-btn {
  width: auto;
  border: 1px solid rgba(0, 107, 30, 0.6);
  color: rgba(0, 107, 30, 0.8);
  padding: 0 10px;
}
.lbDispatchSection .smallContainer,
.app-section .smallContainer {
  width: 60%;
  margin: 65px auto;
}
.lbDispatchSection .smallContainer .formHeader,
.app-section .smallContainer .formHeader {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  margin-bottom: 60px;
}
.lbDispatchSection .dispatchProfile h3,
.app-section .dispatchProfile h3 {
  margin-bottom: 38px;
}
.lbDispatchSection .dispatchProfile .profileContainer,
.app-section .dispatchProfile .profileContainer {
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  background: #fbfdff;
}
.lbDispatchSection .dispatchProfile .profileContainer .profileHeader,
.app-section .dispatchProfile .profileContainer .profileHeader {
  height: 130px;
  background: rgba(39, 109, 247, 0.03);
  border-radius: 5px 5px 0px 0px;
}
.lbDispatchSection .dispatchProfile .profileContainer .profileHeaderWrapper,
.app-section .dispatchProfile .profileContainer .profileHeaderWrapper {
  padding: 30px 60px;
}
.lbDispatchSection .dispatchProfile .profileContainer .profileHeaderWrapper .btn,
.app-section .dispatchProfile .profileContainer .profileHeaderWrapper .btn {
  margin-left: 15px;
}
.lbDispatchSection .dispatchProfile .profileContainer .bigAvatarProfile,
.app-section .dispatchProfile .profileContainer .bigAvatarProfile {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
}
.lbDispatchSection .dispatchProfile .profileContainer .avatarName,
.app-section .dispatchProfile .profileContainer .avatarName {
  margin-left: 15px;
}
.lbDispatchSection .dispatchProfile .profileContainer .profileFormSection .form,
.app-section .dispatchProfile .profileContainer .profileFormSection .form {
  padding: 60px 60px;
}
.form {
  display: flex;
  flex-direction: column;
}
.form .formGrid {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 100px;
}
@media only screen and (max-width: 529px) {
  .form .formGrid {
    flex-direction: column;
  }
}
.form .formGrid .gridItem {
  width: 50%;
  display: flex;
  align-items: baseline;
}
@media only screen and (max-width: 529px) {
  .form .formGrid .gridItem {
    width: 100%;
  }
}
.form .formGrid .gridItem .itemTitle {
  font-family: Muli;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #3b3b3b;
  margin-bottom: 7px;
}
.form .formGrid .gridItem .itemSubTitle {
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}
@media only screen and (max-width: 529px) {
  .form .formGrid .gridItem .itemSubTitle {
    margin-bottom: 30px;
  }
}
.form .formGrid .gridItem .formControl {
  width: 100%;
}
.form .formGrid .gridItem .formControl .itemSelected {
  color: #276df7;
  background: rgba(39, 109, 247, 0.05) !important;
  border: 1.5px solid rgba(39, 109, 247, 0.8) !important;
}
.form .formGrid .gridItem .formControl .itemDisabled {
  opacity: 0.5;
}
.form .formGrid .gridItem .formControl .formFull {
  width: 100%;
  display: flex;
  position: relative;
}
.form .formGrid .gridItem .formControl .formFull .mr-10 {
  margin-right: 10% !important;
}
.form .formGrid .gridItem .formControl .formFull .mr-20 {
  margin-right: 20px;
}
@media only screen and (max-width: 529px) {
  .form .formGrid .gridItem .formControl .formFull .mr-20 {
    margin: 0;
  }
}
.form .formGrid .gridItem .formControl .formFull .select {
  margin-bottom: 25px !important;
}
.form .formGrid .gridItem .formControl .formFull .radio-button-error {
  position: absolute;
  bottom: -25px;
  font-size: 14px;
  color: #bc3e06;
  width: 100%;
}
.form .formGrid .gridItem .formControl .formInputWrapper {
  height: 65px;
  background: #ffffff;
  border: 1px solid rgba(130, 127, 152, 0.4);
  border-radius: 8px;
  margin-bottom: 25px;
}
.form .formGrid .gridItem .formControl .formInputWrapper input {
  width: 95%;
  margin: 0;
  padding: 0;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
@media only screen and (max-width: 529px) {
  .form .formGrid .gridItem .formControl .formInputWrapper input {
    width: 85%;
  }
}
.form .formGrid .gridItem .formControl .formInputWrapper input:focus,
.form .formGrid .gridItem .formControl .formInputWrapper input:active {
  border: none;
  outline: 0;
}
.form .formGrid .gridItem .formControl .formWrapper {
  height: 65px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 20px;
  border: 1px solid rgba(130, 127, 152, 0.4);
  margin-bottom: 25px;
}
.form .formGrid .gridItem .formControl .formWrapper label {
  color: #827f98;
  font-weight: 500;
  font-size: 13px;
}
.form .formGrid .gridItem .formControl .formWrapper input[type="text"],
.form .formGrid .gridItem .formControl .formWrapper input[type="email"],
.form .formGrid .gridItem .formControl .formWrapper input[type="date"] {
  width: 100%;
  height: inherit;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  padding: 0px;
  color: #3b3b3b;
  font-weight: 600;
  font-size: 16px;
}
.form .formGrid .gridItem .formControl .formWrapper.flexColumn {
  display: flex;
  flex-direction: column;
}
.form .formGrid .gridItem .formControl .formWrapper.formHalf {
  width: 50%;
}
@media only screen and (max-width: 529px) {
  .form .formGrid .gridItem .formControl .formWrapper.formHalf {
    width: 45%;
  }
}
.table {
  width: 100%;
  height: auto;
  margin-top: 35px;
}
.table table {
  width: 100%;
  border-collapse: collapse;
}
.table th {
  height: 60px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  text-align: start;
}
.table tr {
  border-bottom: 1px solid green;
}
.table .tr {
  height: 98px !important;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1) !important;
}
.table table {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  border-radius: 5px;
}
.table table .th-center,
.table table .td-center {
  text-align: center;
}
.table table td.avatar_td {
  width: 108px;
  box-sizing: border-box;
}
.table table td.avatar_td .avatar {
  width: 48px;
  height: 48px;
  background: #f1743a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.table table button.view-btn,
.table table button.reschedule-btn {
  height: 36px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  white-space: no-wrap;
}
.table table button.view-btn {
  border: 1px solid rgba(39, 109, 247, 0.6);
  color: #276df7;
  width: auto;
  white-space: nowrap;
}
.table table button.reschedule-btn {
  width: auto;
  border: 1px solid rgba(0, 107, 30, 0.6);
  color: rgba(0, 107, 30, 0.8);
  padding: 0 10px;
}
section.assign-kit .back {
  margin-bottom: 37px;
}
section.assign-kit .header {
  margin-bottom: 53px;
}
section.assign-kit .header h4 {
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #18131a;
}
section.assign-kit .header .bold {
  font-weight: bold;
}
section.assign-kit .header ul {
  list-style: none;
}
section.assign-kit .header ul li:not(:last-child) {
  margin-right: 30px;
}
section.assign-kit .header ul li img {
  margin-right: 10px;
}
section.assign-kit .filter-section {
  margin-bottom: 53px;
}
section.assign-kit .filter-section .filters .badge {
  background: rgba(130, 127, 152, 0.07);
  border-radius: 7px;
  box-sizing: border-box;
  width: auto;
  height: 34px;
  padding: 10px 20px;
  margin-right: 20px;
}
section.assign-kit .filter-section .filters .badge.active {
  background: rgba(39, 109, 247, 0.1);
  border: 1px solid rgba(39, 109, 247, 0.5);
  color: #276df7;
  font-weight: bold;
  border-radius: 7px;
  width: auto;
  height: 34px;
}
section.assign-kit .filter-section .search-filter .search-wrapper {
  margin-right: 20px;
}
section.assign-kit .filter-section .search-filter .search-wrapper,
section.assign-kit .filter-section .search-filter .sort-wrapper {
  background: linear-gradient(180deg, #ffffff 0.86%, #fefefe 100%);
  border: 1px solid rgba(38, 38, 38, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 14px;
}
section.assign-kit .filter-section .search-filter .search-wrapper.hide,
section.assign-kit .filter-section .search-filter .sort-wrapper.hide {
  display: none !important;
}
section.assign-kit .filter-section .search-filter .search-wrapper svg,
section.assign-kit .filter-section .search-filter .sort-wrapper svg {
  margin-right: 10px;
}
section.assign-kit .filter-section .search-filter .search-wrapper input[type="text"],
section.assign-kit .filter-section .search-filter .sort-wrapper input[type="text"] {
  border: none;
  outline: none;
}
.kits-list,
.listings {
  width: 90%;
  margin: 0 auto;
  padding: 64px 0 100px;
}
.kits-list .top,
.listings .top,
.kits-list .content,
.listings .content {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 40px;
}
.kits-list .title-search,
.listings .title-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 30px;
}
.kits-list .title-search h4,
.listings .title-search h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #2d2d2d;
}
.kits-list .title-search .searchInputWrapper,
.listings .title-search .searchInputWrapper {
  background: linear-gradient(180deg, #ffffff 0.86%, #fefefe 100%);
  border: 1px solid rgba(38, 38, 38, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  width: 290px;
  padding: 0 16px;
}
.kits-list .title-search .searchInputWrapper svg,
.listings .title-search .searchInputWrapper svg {
  margin-right: 12px;
}
.kits-list .title-search .searchInputWrapper input,
.listings .title-search .searchInputWrapper input {
  width: 80%;
  border: none;
  outline: none;
}
.kits-list .sideBarNav,
.listings .sideBarNav {
  width: 100%;
  height: auto;
  background: rgba(39, 109, 247, 0.03);
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px #fdfbfb;
  border-radius: 5px;
}
.kits-list .sideBarNav ul,
.listings .sideBarNav ul {
  padding-top: 20px;
  list-style: none;
}
.kits-list .sideBarNav ul li,
.listings .sideBarNav ul li {
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  padding-left: 46px;
  cursor: pointer;
}
.kits-list .sideBarNav ul li.focus,
.listings .sideBarNav ul li.focus {
  border-right: 3px solid #276df7;
  background: rgba(130, 127, 152, 0.1);
}
.kits-list .sideBarNav ul li.focus div,
.listings .sideBarNav ul li.focus div {
  color: #18131a !important;
}
.kits-list .dispatchProfile h3,
.listings .dispatchProfile h3 {
  margin-bottom: 38px;
}
.kits-list .dispatchProfile .profileContainer,
.listings .dispatchProfile .profileContainer {
  border: 1px solid rgba(130, 127, 152, 0.1);
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  background: #fbfdff;
}
.kits-list .dispatchProfile .profileContainer .profileHeader,
.listings .dispatchProfile .profileContainer .profileHeader {
  height: 130px;
  background: rgba(39, 109, 247, 0.03);
  border-radius: 5px 5px 0px 0px;
}
.kits-list .dispatchProfile .profileContainer .profileHeaderWrapper,
.listings .dispatchProfile .profileContainer .profileHeaderWrapper {
  padding: 30px 60px;
}
.kits-list .dispatchProfile .profileContainer .profileHeaderWrapper .btn,
.listings .dispatchProfile .profileContainer .profileHeaderWrapper .btn {
  margin-left: 15px;
}
.kits-list .dispatchProfile .profileContainer .bigAvatarProfile,
.listings .dispatchProfile .profileContainer .bigAvatarProfile {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
}
.kits-list .dispatchProfile .profileContainer .avatarName,
.listings .dispatchProfile .profileContainer .avatarName {
  margin-left: 15px;
}
.kits-list .dispatchProfile .profileContainer .profileFormSection .form,
.listings .dispatchProfile .profileContainer .profileFormSection .form {
  padding: 60px 60px;
}
.kits-list .badges,
.listings .badges {
  height: 27px;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 5px;
  white-space: nowrap;
  padding: 0 10px;
  border: none;
}
.kits-list .badges.default,
.listings .badges.default {
  background: #827f98;
  color: #827f98;
}
.kits-list .badges.new,
.listings .badges.new {
  background: red;
}
.kits-list .badges.result_updated,
.listings .badges.result_updated {
  background: #e3fcef;
  color: #00875a;
}
.kits-list .badges.sample_collected,
.listings .badges.sample_collected {
  color: #f1743a;
  background: #fdf1eb;
}
.kits-list .badges.sample_delivered,
.listings .badges.sample_delivered {
  color: #ff991f;
  background: #fef5e9;
}
.kits-list .badges.kit_delivered,
.listings .badges.kit_delivered {
  background: #e7e8f8;
  color: #0d1cb8;
}
.kits-list .badges.pending,
.listings .badges.pending {
  background: #f3f2f5;
  color: #827f98;
}
.kits-list .badges.lab_received,
.listings .badges.lab_received {
  color: #ff991f;
  background: #fef5e9;
}
.kits-list .badges.negative,
.listings .badges.negative {
  background: #e3fcef;
  color: #00875a;
}
.kits-list .badges.positive,
.listings .badges.positive {
  background: #fbe8e8;
  color: #de1e1e;
}
.listing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 38px;
}
.listing .card {
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(130, 127, 152, 0.15);
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  border-radius: 5px;
}
.listing .card .card-header {
  border-bottom: 1px solid rgba(130, 127, 152, 0.15);
}
.listing .card .card-header .card-header-wrapper {
  padding: 30px;
}
.listing .card .card-header .card-header-wrapper .card-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 15px;
}
.listing .card .card-header .card-header-wrapper .card-avatar h4 {
  color: #262626;
}
.listing .card .card-body {
  padding: 30px;
}
.listing .card .card-body .card-item {
  margin-bottom: 35px;
}
.listing .card .card-body .card-item .view-btn {
  background: rgba(254, 244, 244, 0.01);
  border: 1px solid #276df7;
  box-sizing: border-box;
  border-radius: 100px;
  width: 84px;
  height: 35px;
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #276df7;
  outline: none;
}
.listing .card .card-body div.card_label {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #827f98;
}
.listing .card .card-body div.item-value {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(24, 19, 26, 0.8);
}
.listing .card .card-body p.case-count {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #18131a;
  margin-left: 10px;
}
.upload-result {
  width: 60%;
  margin: 0 auto;
  padding: 64px 0 100px;
}
.upload-result .top {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
}
.upload-result .top h4 {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  color: #18131a;
}
.upload-result .content h5 {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #3b3b3b;
  margin-bottom: 10px;
}
.upload-result .content .select-result {
  display: flex;
}
.upload-result .content .select-result p {
  width: 45%;
  margin-right: auto;
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3b3b3b;
}
.upload-result .content .select-result span {
  margin-left: 90px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #827f98;
}
.upload-result .content .button-div {
  margin-top: 250px;
}
.scan-kit {
  width: 100%;
}
.scan-kit .container {
  width: 50%;
  margin: 0 auto;
}
.scan-kit .container .scan-wrapper {
  min-width: 316px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center;
}
.scan-kit .container .scan-wrapper .p-20 {
  padding-top: 20px !important;
}
.scan-kit .container .scan-wrapper .kitCode {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  color: #5d6c85 !important;
}
.scan-kit .container .scan-wrapper svg.cancel {
  color: #5e6c84;
}
.scan-kit .container .scan-wrapper h5,
.scan-kit .container .scan-wrapper img {
  margin-bottom: 35px;
}
.scan-kit .container .scan-wrapper .select-kit {
  width: 316px;
  height: 75px;
  border-radius: 20px;
  margin: 0 auto;
  background: #edeff4;
  padding: 0 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  /* identical to box height */
  color: #5e6c84;
}
.scan-kit .container .scan-wrapper .scan-kit-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 248px;
  height: 60px;
  margin: 70px auto;
  background: #276df7;
  border-radius: 50px;
  border: none;
  outline: none;
  box-shadow: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}
.modal-main {
  position: fixed;
  background: white;
  width: 30%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 249px;
  border-radius: 10px;
}
.modal-main .close-menu-wrapper {
  margin-top: 29px;
  padding: 0 24.5px;
}
.modal-main .modal-container {
  padding: 0 24.5px;
}
.modal-main .modal-container h4 {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #18131a;
  opacity: 0.8;
  margin-bottom: 15px;
}
.modal-main .modal-container p {
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #18131a;
}
.modal-main .modal-container .buttons-wrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.modal-main .modal-container .buttons-wrapper .go-back {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #276df7;
  cursor: pointer;
}
.modal-main .modal-container .react-datepicker-wrapper {
  width: 100%;
  height: 0;
  position: absolute;
}
.modal-main .modal-container .react-datepicker-wrapper .react-datepicker__input-container input {
  display: none;
}
.modal-main .modal-container .date-div {
  height: 45px;
  padding: 0 20px;
  background: #f3f2f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.modal-main .modal-container .date-div svg {
  margin-right: 16px;
}
.modal-main .modal-container .date-div p {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #3b3b3b;
  opacity: 0.8;
}
.modal-main .modal-container .date-div span {
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #276df7;
  opacity: 0.9;
  cursor: pointer;
}
.modal-main .modal-btn {
  width: 104px;
  height: 36px;
  background: #276df7;
  border-radius: 100px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  outline: none;
  box-shadow: none;
  border: none;
  margin-left: 30px;
}
.modal-main .modal-btn.confirm-green {
  background: rgba(0, 107, 30, 0.8) !important;
  color: #FFFFFF;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.table {
  margin-top: 0;
}
.table table tr th:first-child,
.table table tr td:first-child {
  padding-left: 40px;
}
.table table tr th:last-child,
.table table tr td:last-child {
  padding-right: 40px;
  width: 140px;
}
.table table tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: rgba(38, 38, 38, 0.7);
}
.table table tr td {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(38, 38, 38, 0.8);
}
.table table tr td .view-btn {
  width: auto;
  padding: 0 16px;
}
.table table tr td .status {
  width: 71px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  margin-left: auto;
  text-transform: capitalize;
}
.table table tr td .status.negative {
  background: #e3fcef;
  color: #00875a;
}
.table table tr td .status.positive {
  background: #fbe8e8;
  color: #de1e1e;
}
.loader-container {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
}
.overview-page {
  width: 90%;
  margin: 0 auto;
  padding: 80px 0;
}
.overview-page .overview-top {
  background: #ecf2fe;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.overview-page .overview-top .item {
  width: 25%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: 156px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}
.overview-page .overview-top .item h3 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: #0a2e65;
}
.overview-page .overview-top .item h3.negative {
  color: rgba(0, 128, 0, 0.7);
}
.overview-page .overview-top .item h3.positive {
  color: #de1e1e;
  opacity: 0.7;
}
.overview-page .overview-top .item p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(10, 46, 101, 0.5);
}
.overview-page .chart-div {
  background: #ffffff;
  border: 1px solid rgba(130, 127, 152, 0.08);
  border-radius: 5px;
}
.overview-page .chart-div .title-key {
  padding: 30px 50px;
  border-bottom: 1px solid rgba(38, 38, 38, 0.07);
  display: flex;
  align-items: center;
}
.overview-page .chart-div .title-key p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(38, 38, 38, 0.6);
}
.overview-page .chart-div .title-key p span {
  margin-right: 6px;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  display: inline-block;
}
.overview-page .chart-div .title-key p.title {
  font-weight: bold;
  color: rgba(38, 38, 38, 0.8);
  margin-right: auto;
}
.overview-page .chart-div .title-key p.negative {
  margin-right: 50px;
}
.overview-page .chart-div .title-key p.negative span {
  background: rgba(51, 153, 51, 0.8);
}
.overview-page .chart-div .title-key p.positive span {
  background: rgba(229, 75, 75, 0.8);
}
.overview-page .chart-div .chart {
  padding: 40px 60px;
}
.overview-page .chart-div .chart p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #276df7;
  margin-bottom: 20px;
  font-weight: bold;
}
.overview-page .chart-div .chart p.month {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
  margin-bottom: 0;
}
.overview-page .admin-overview-list .title-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 30px;
}
.overview-page .admin-overview-list .title-search h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #2d2d2d;
}
.overview-page .admin-overview-list .title-search .searchInputWrapper {
  background: linear-gradient(180deg, #ffffff 0.86%, #fefefe 100%);
  border: 1px solid rgba(38, 38, 38, 0.1);
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  width: 290px;
  padding: 0 16px;
}
.overview-page .admin-overview-list .title-search .searchInputWrapper svg {
  margin-right: 12px;
}
.overview-page .admin-overview-list .title-search .searchInputWrapper input {
  width: 80%;
  border: none;
  outline: none;
}
.overview-page .admin-overview-list table tr td:first-child {
  width: 130px;
  box-sizing: border-box;
}
.overview-page .admin-overview-list table tr td:nth-child(2) {
  width: 35%;
}
.overview-page .admin-overview-list table tr td .view-btn.unblock {
  color: rgba(0, 107, 30, 0.8);
  border: 1px solid rgba(0, 107, 30, 0.6);
}
